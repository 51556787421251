import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import  { environment } from '../../environments/environment'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Api-Key '+ environment.api_key
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public successUser = new BehaviorSubject<string>('');
  successUserChange = this.successUser.asObservable();
  constructor(
    private http:HttpClient
  ) { }

  changeSuccessMessage(message: string) {
    this.successUser.next(message)
  }

  heckEmail = (email:string) =>  {
    return this
            .http
            .get(`${environment.retrieveEmail}`+ email,httpOptions);
  }
  signUp = (user:any) => {
    return this.http.post(environment.signUp,user);
  }
  passwordReset = (user:any) => {
    return this.http.post(environment.resetPassword,user);
  }
  sendCode = (webformCode:any) => {
    return this.http.post(environment.codePass,webformCode);
  } 

  sendUpdatePassword = (webformCode:any) => {
    return this.http.patch(environment.updateCodePass + webformCode.token,webformCode);
  } 

  
  login = (user:any) => {
    return this.http.post(`${environment.loginUser}`,user);
  }
  getProfile = (user:any) => {
    const profileOptions = {
        headers: new HttpHeaders({
         "Authorization": "Bearer "+ user.token
        })
      }
    return this
            .http
            .get(`${environment.getProfile}`,profileOptions);
  }
  // getTrips(phone:string){
  //   const profileOptions = {
  //     headers: new HttpHeaders({
  //      "Authorization": "Token "+ environment.api_key
  //     })
  //   }
  //   return this
  //           .http
  //           .get(`${environment.getHistory}`+ phone,profileOptions);
  // }
  getTrips(phone:string){
    return this
            .http
            .get(`${environment.getTrips}`+ phone);
  }
}
