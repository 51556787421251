
<div class="alert alert-warning-footer alert-dismissible fade show text-center" role="alert">
  <i class="fa fa-regular fa-bell fa-2xs" style="color: #fff;"></i>
  The official <span class="f-e-footer">e</span><span class="f-easy-footer">asycoach</span> website.
  <i class="fa fa-regular fa-bell fa-2xs" style="color: #fff;"></i>
  </div>
    <div class="theme-footer" id="mainFooter">
        <div class="container _ph-mob-0">
          <div class="row row-eq-height row-mob-full" data-gutter="60">
            <div class="col-lg-4">
              <div class="theme-footer-section theme-footer-">
                <a class="theme-footer-brand _mt-20" href="#">
                  <img src="assets/images/logo.webp" alt="Easycoach"  title="Image Title"/>
                </a>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-md-3">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Our Company</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <a [routerLink]="['/about-us']">About us</a>
                      </li>
                      <li>
                        <a [routerLink]="['/contact-us']">Contact us</a>
                      </li>
                      <li>
                        <a  [routerLink]="['/terms-and-condition']">Terms & Conditions </a>
                      </li>
                      <li>
                        <a  [routerLink]="['/privacy-policy']">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
    
                <div class="col-md-3 col-lg-3 col-sm-12">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Our Contacts</h5>
                    <ul class="theme-footer-section-list">
                      <li>
                        <span class="phones-wrapper"><a class="contacts-links" href="tel:+254726354300">0726 354 300</a> </span><br>
                        <span class="phones-wrapper"><a class="contacts-links" href="tel:+254726354301">0726 354 301</a> </span><br>
                        <span class="phones-wrapper"><a class="contacts-links" href="tel:+254733444244">0738 200 300</a> </span><br>
                        
                      </li>
                      <li>
                        <h6 class="phone-titles"> Email:</h6>
                        <span class="phones-wrapper"><a href="mailto:support@easycoachkenya.com">support@easycoachkenya.com</a></span><br>
                        <span class="phones-wrapper"><a href="mailto:bookings@easycoachkenya.com">bookings@easycoachkenya.com</a></span><br>
                        
                      </li>
    
                    </ul>
                  </div>
    
                </div >
    
                <div class="col-md-3 col-lg-3 col-sm-12 mobile-hidden">
                  <div class="theme-footer-section theme-footer-">
                    <h5 class="theme-footer-section-title">Top Routes</h5>
                    <ul class="theme-footer-section-list">
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Kitale')" >Nairobi - Kitale</a>
                      </li>
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Sirare')" >Nairobi - Sirare</a>
                      </li>
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Mumias')" >Nairobi - Mumias </a>
                      </li>
                      <li class="change-city">
                        <a (click)="clickTopCity('Nairobi','Busia')" >Nairobi - Busia </a>
                      </li>
                    </ul>
                  </div>
                </div>
    
                <div class="col-md-3 col-lg-3 col-sm-12 mobile-app-cta">
                  <div class="_mt-40 mobile-app-cta-inner text-center">
                      <h3 class="_ml-10 ">GET THE APP!</h3>
                      <a class="centered-img" href="https://play.google.com/store/apps/details?id=easycoachltd.co.ke&hl=en&gl=US&pli=1">
                        <img src="assets/img/company1/app-logo-GooglePlay.png" alt="Download on play store" class="icon-app-store">
                    </a>
                    <ul class="theme-copyright-social _mt-10">
                      <li>
                        <a class="fa fa-facebook icon-color-facebook fa-2x"  href="https://www.facebook.com/EasyCoachOfficialPage" target="_blank"></a>
                      </li>
      
                      <li>
                        <a class="fa fa-twitter icon-color-twitter fa-2x" href="https://twitter.com/EasyCoachltd_" target="_blank"></a>
                      </li>
           
                    </ul>
                  </div>
                </div>
              </div>
              
            </div>

            

          </div>
        </div>
      </div>
      <div class="theme-copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <p class="theme-copyright-text">Copyright &copy; 2022
                <a href="/">Easycoach</a>. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="cookies-eu-banner hidden">
        By using Easycoach's services, you agree to our Cookies Use. We use cookies for analytics and personalisation.  <button id="cookie-button" class="cookie-button"> Close</button>
      </div>
