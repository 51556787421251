import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CarhireService } from '../services';

import { formatDate   } from '@angular/common';
@Component({
  selector: 'app-carhire',
  templateUrl: './carhire.component.html',
  styleUrls: ['./carhire.component.css']
})
export class CarhireComponent {
  carhire: UntypedFormGroup;
  submitted:boolean = false;
loadingCarhire:boolean = false;
success:boolean = false;
resMsg:any;
res:any
errorMessage:any;
bsInlineValue = new Date();
today=new Date();
isHidden:boolean = true;
  constructor(     
    private _fb: UntypedFormBuilder,
    private carhireService: CarhireService
    ) { 
    this.carhire = _fb.group({
      fullname:['', Validators.required],
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      pickup_date:['', Validators.required],
      pickup_time:['', Validators.required],
      dropoff_date:['', Validators.required],
      dropoff_time:['', Validators.required],
      contact_person:['', Validators.required],
      email:['', Validators.required],
      car_type:['', Validators.required],
      seat_capacity:['', Validators.required],
    });
  }

  get f() { return this.carhire.controls; }

  submitCarhire = () => {
    this.submitted = true;
    this.loadingCarhire=true;
    // stop here if form is invalid
    if (this.carhire.invalid ) {
      this.submitted = false;
      this.loadingCarhire=false;
        return;
    }
    let value = this.carhire.value;
    var pickup = formatDate(value.pickup_date,"YYYY-MM-dd","en-KE")
    var dropoff = formatDate(value.dropoff_date,"YYYY-MM-dd","en-KE")
    
    let webformdata={
      "full_name": value.pickup,
      "pickup": value.pickup,
      "drop_off":value.dropoff,
      "pickup_date": pickup,
      "pickup_time": value.pickup_time,
      "dropoff_date": dropoff,
      "dropoff_time": value.dropoff_time,
      "contact_person": value.contact_person,
      "email": value.email,
      "car_type": value.car_type,
      "seat_capacity": value.seat_capacity,
    }
    this.carhireService.submitCarhire(webformdata).subscribe(
      response => {
        this.loadingCarhire=false;
          this.success = true;
          this.resMsg=response;
          this.res= this.resMsg.data.message;
          this.carhire.reset();
          document.getElementById("openSuccessButton").click();

          setTimeout (() => {
            document.getElementById("closeSuccess").click();
          }, 5000);

      },
      error => {
          this.success = false;
          this.loadingCarhire=false;
          this.errorMessage = error.error.errors[0]

      }
    );
}

}


