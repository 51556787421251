import { Deserializable } from './deserializable.model';

export class Passenger implements Deserializable {
    
    customer_type: string;
    first_name:string;
    last_name: string;
    phone_number: string;
    id_number:string;
    luggage: string;
    seat: {}
    deserialize(input: any): this{
        Object.assign(this, input);
        return this;
    }

}


  