import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':  'Api-Key '+ environment.api_key
  })
};
@Injectable({
  providedIn: 'root'
})
export class CarhireService {

  constructor(  private http:HttpClient) { }


  submitCarhire = (data:any) => {
    return this.http.post(environment.submitCarhire,data,httpOptions);
  }
}
