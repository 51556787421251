<nav class="navbar navbar-default navbar-theme" id="main-nav">
    <div class="container">
      <div class="navbar-inner nav">
        <div class="navbar-header">
          <button class="navbar-toggle collapsed" data-target="#navbar-main" data-toggle="collapse" type="button" area-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="index.html">
            <img src="assets/img/company1/logo.png" alt="buupass.com" width="70" alt="Image Alternative text" title="Image Title"/>
          </a>
        </div>
        <div class="collapse navbar-collapse" id="navbar-main">
         
          <ul class="nav navbar-nav pd-nav">
            <li>
              <a routerLink="/" role="button" >
                <b>Home</b>
              </a>
            </li>
            <li>
              <a routerLink="/about-us" role="button" >
                <b>About Us</b>
              </a>
            </li>
            <li>
              <a routerLink="/contact-us" role="button" >
                <b>Contact Us</b>
              </a>
            </li>
        </ul>
          </div>
      </div>
    </div>
  </nav>