<app-headerhome class="header-sec"></app-headerhome>

<!-- ================================
    START BREADCRUMB AREA
================================= -->
<section class="breadcrumb-area gradient-bg-gray before-none">
    <div class="breadcrumb-wrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between">
                        <div class="section-heading text-left">
                            <h2 class="sec__title">Terms and Conditions</h2>
                            <p class="sec__desc font-weight-regular pb-2">Easycoach</p>
                        </div>
                        <img src="assets/images/eterms.png" width="200">
                        <div class="btn-box bread-btns">
                            <a href="/T&C-25.07.2023.pdf" class="theme-btn theme-btn-gray mr-2"><i class="la la-bookmark mr-1"></i>PDF</a>
                        </div>
                    </div><!-- end breadcrumb-content -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </div><!-- end breadcrumb-wrap -->
    <div class="bread-svg-box">
        <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
    </div><!-- end bread-svg -->
</section><!-- end breadcrumb-area -->
<!-- ================================
    END BREADCRUMB AREA
================================= -->

<!-- ================================
    START PRIVACY Policy AREA
================================= -->
<section class="job-details-area section--padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-10">
                <div class="form-box">
                   
                    <div class="form-title-wrap">
                        <h2 class="title"><u> Passenger Ticket Terms and Conditions</u></h2>
                    </div>
                    <div class="form-title-wrap">
                        <h3 class="title"><u>General Terms & Conditions</u></h3>
                    </div><!-- form-title-wrap -->
                    <div class="form-content contact-form-action">
                        <ul class="_ml-20 pb-4" style="list-style-type: disc;">
                            <li>Your purchase of this ticket indicates your agreement with the <a href="terms_and_conditions_link">Terms and Conditions</a> and the Easy Coach Limited <a href="privacy_policy_link">Privacy Policy</a>.</li>
                            <li>It is the responsibility of the person purchasing this ticket to confirm the accuracy of this ticket at the time of issuance.</li>
                            <li>Management reserves the right to change the coach type, time, seat number, and departure time without prior notice.</li>
                            <li>In compliance with the Insurance and NTSA regulations, children over 3 years of age must purchase a valid ticket.</li>
                            <li>All tickets are valid for the date and time of travel as indicated on it.</li>
                            <li>Smoking and Drinking are not permitted inside the coach.</li>
                        </ul>
                            
  
                        <h3 class="title"><u>Modification and Cancellation</u></h3>
                            <ul class="_ml-20 pb-4" style="list-style-type: disc;">
                                <li>You may modify or cancel your ticket once for free as long as you do so at least two (2) hours before 
                                    the scheduled departure time; any changes or cancellations made after this time will incur a fee of 
                                    Kshs.300/=.</li>
                                <li>Tickets that are cancelled before the scheduled departure time remain valid for 90 days after the 
                                    original departure date.</li>
                                <li>If you don't cancel the ticket before the departure time, and fail to show up for your ticketed journey, 
                                    you will be considered as “A NO SHOW PASSENGER” and you will be required to pay 50% of the 
                                    original ticket price to allow you to travel within the next 24 hours subject to space available.</li>
                                <li>In the event of a lost/forgotten ticket, passengers will be required to buy a new ticket at the original 
                                    fare. The original ticket once retrieved by the ticket owner can be validated within 90 days from the 
                                    initial date of travel.</li>
                                <li>Passengers may also modify or cancel their ticket by coming in person to any of our locations or by sending an email to <a href="mailto: cancellation@easycoachkenya.com"> cancellation@easycoachkenya.com</a></li>
                                <li>Cancelled printed ticket claims should be submitted at least seventy-two (72) hours prior to intended 
                                    travel date, supported with a copy of your ID.</li>
                                <li>For cancelled un-printed ticket claims, a copy of your ID should be submitted by email to <a href="mailto:cancellation@easycoachkenya.com">cancellation@easycoachkenya.com</a> 
                                    or in person to any of our offices at least seventy-two (72) hours prior to the intended travel date.</li>
                            </ul>
                            <h3 class="title"><u>Refunds and Transfers</u></h3>
                                <ul class="_ml-20 pb-4" style="list-style-type: disc;">
                                    <li>The Ticket is not transferable to another person nor refundable.</li>
                                    <li>If in any case a passenger breaks their journey en-route, no refund will be made.</li>
                                </ul>
                            <h3 class="title"><u>Luggage</u></h3>
                            <ul class="_ml-20 pb-4" style="list-style-type: disc;">
                                    <li>Management is not responsible for any loss, theft or damages to the goods or property of the passenger.</li>
                                    <li>Each passenger is allowed to check-in one free bag that weighs no more than 20 kilograms. In accordance with Easy Coach's terms and conditions, and with full payment of excess luggage.</li>
                                    <li>Items such as contraband, including but not limited to liquids and flammable items, are not allowed to be carried by any passenger.</li>
                                    <li>Pets, animals, and birds are not permitted in the coach.</li>
                                </ul>

                       
                      
                       
                       
                        
                       


                    </div><!-- end form-content -->
                </div><!-- end form-box -->
            </div><!-- end col-lg-8 -->
        </div><!-- end row -->
    </div><!-- end container -->
</section><!-- end PRIVACY-POLICY-area -->
<!-- ================================
    END PRIVACY-POLICY AREA
================================= -->
<app-footer></app-footer>