import { Component } from '@angular/core';
import { User } from '../models/index'
import { UserService } from '../services/user.service'
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
  isLoggedIn:any;
  user:User;
  api_error:Error;
  loadingStart:boolean = false;
  resMsg:any


  constructor( private users:UserService) {

  }

  logoutUser(){
    localStorage.removeItem("userEasy");
    sessionStorage.setItem('isLoggedInEasy','false')
    localStorage.setItem('isLoggedInEasy','false');
  }

  ngOnInit() {

    this.isLoggedIn = sessionStorage.getItem('isLoggedInEasy');
    console.log(this.isLoggedIn);

    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(localStorage.getItem('userEasy')))
      console.log(this.user);
      this.getUserProfile(this.user);
    }
  }


  getUserProfile(user:any){
    this.users.getProfile(user).subscribe(
      response => {
          this.loadingStart=false;
          this.resMsg=response;
          this.user = this.resMsg.data
          console.log(this.user);
          localStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
          sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
      },
      error => {
        localStorage.removeItem("userEasy");
        // this.isLoggedIn=false;
      }
    );
  }

}
