<div class="col-md-12 ">
  <div class="placeholder placeholder--list">
    <div class="placeholder__layer placeholder__gradient-slide"></div>
    <svg width="100%" version="1.0" xmlns="http://www.w3.org/2000/svg" height="142.000000pt"
      viewBox="0 0 1230.000000 142.000000" class="placeholder__layer placeholder__neg-shape"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,142.000000) scale(0.100000,-0.100000)">
        <path fill="#ffffff" d="M0 710 l0 -710 6150 0 6150 0 0 110 0 110 -1590 0 -1590 0 0 210 0
210 1590 0 1590 0 0 390 0 390 -3275 0 -3275 0 0 -705 c0 -620 -2 -705 -15
-705 -13 0 -15 85 -15 705 l0 705 -2860 0 -2860 0 0 -710z m2520 470 l0 -150
-1145 0 -1145 0 0 150 0 150 1145 0 1145 0 0 -150z m9770 -55 l0 -205 -690 0
-690 0 0 205 0 205 690 0 690 0 0 -205z m-6890 -560 l0 -255 -2580 0 -2580 0
0 255 0 255 2580 0 2580 0 0 -255z" />
      </g>
    </svg>
    <div class="placeholder__layer placeholder__bg"></div>
  </div>
  <br>
</div>

<div class="col-md-12 ">
  <div class="placeholder placeholder--list">
    <div class="placeholder__layer placeholder__gradient-slide"></div>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="1230.000000pt" height="142.000000pt"
      viewBox="0 0 1230.000000 142.000000" class="placeholder__layer placeholder__neg-shape"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,142.000000) scale(0.100000,-0.100000)">
        <path fill="#ffffff" d="M0 710 l0 -710 6150 0 6150 0 0 110 0 110 -1590 0 -1590 0 0 210 0
210 1590 0 1590 0 0 390 0 390 -3275 0 -3275 0 0 -705 c0 -620 -2 -705 -15
-705 -13 0 -15 85 -15 705 l0 705 -2860 0 -2860 0 0 -710z m2520 470 l0 -150
-1145 0 -1145 0 0 150 0 150 1145 0 1145 0 0 -150z m9770 -55 l0 -205 -690 0
-690 0 0 205 0 205 690 0 690 0 0 -205z m-6890 -560 l0 -255 -2580 0 -2580 0
0 255 0 255 2580 0 2580 0 0 -255z" />
      </g>
    </svg>
    <div class="placeholder__layer placeholder__bg"></div>
  </div>
  <br>
</div>
<div class="col-md-12 ">
  <div class="placeholder placeholder--list">
    <div class="placeholder__layer placeholder__gradient-slide"></div>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="1230.000000pt" height="142.000000pt"
      viewBox="0 0 1230.000000 142.000000" class="placeholder__layer placeholder__neg-shape"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,142.000000) scale(0.100000,-0.100000)">
        <path fill="#ffffff" d="M0 710 l0 -710 6150 0 6150 0 0 110 0 110 -1590 0 -1590 0 0 210 0
210 1590 0 1590 0 0 390 0 390 -3275 0 -3275 0 0 -705 c0 -620 -2 -705 -15
-705 -13 0 -15 85 -15 705 l0 705 -2860 0 -2860 0 0 -710z m2520 470 l0 -150
-1145 0 -1145 0 0 150 0 150 1145 0 1145 0 0 -150z m9770 -55 l0 -205 -690 0
-690 0 0 205 0 205 690 0 690 0 0 -205z m-6890 -560 l0 -255 -2580 0 -2580 0
0 255 0 255 2580 0 2580 0 0 -255z" />
      </g>
    </svg>
    <div class="placeholder__layer placeholder__bg"></div>
  </div>
  <br>
</div>
<div class="col-md-12 ">
  <div class="placeholder placeholder--list">
    <div class="placeholder__layer placeholder__gradient-slide"></div>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="1230.000000pt" height="142.000000pt"
      viewBox="0 0 1230.000000 142.000000" class="placeholder__layer placeholder__neg-shape"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,142.000000) scale(0.100000,-0.100000)">
        <path fill="#ffffff" d="M0 710 l0 -710 6150 0 6150 0 0 110 0 110 -1590 0 -1590 0 0 210 0
210 1590 0 1590 0 0 390 0 390 -3275 0 -3275 0 0 -705 c0 -620 -2 -705 -15
-705 -13 0 -15 85 -15 705 l0 705 -2860 0 -2860 0 0 -710z m2520 470 l0 -150
-1145 0 -1145 0 0 150 0 150 1145 0 1145 0 0 -150z m9770 -55 l0 -205 -690 0
-690 0 0 205 0 205 690 0 690 0 0 -205z m-6890 -560 l0 -255 -2580 0 -2580 0
0 255 0 255 2580 0 2580 0 0 -255z" />
      </g>
    </svg>
    <div class="placeholder__layer placeholder__bg"></div>
  </div>
  <br>
</div>