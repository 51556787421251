<app-headerhome class="header-sec"></app-headerhome>
<div class="theme-page-section theme-page-section-dark">
        <div class="container">
          <div class="theme-search-area _mob-h theme-search-area-white">
            <div class="row">
              <div class="col-md-2 ">
                <div class="theme-search-area-header theme-search-area-header-sm">
                  <h1 class="theme-search-area-title">{{ pickUpInit }} - {{ dropOffInit }}</h1>
                  <p class="theme-search-area-subtitle">{{ travelDateInit }}</p>
                </div>
              </div>
              <div class="col-md-10 ">
                <div class="theme-search-area-form" id="hero-search-form">
                    <form  autocomplete="off" novalidate #reactiveForm = "ngForm" (ngSubmit)="submitWebForm(reactiveForm.value)">
                  <div class="row" data-gutter="10">

                        <div class="col-md-3 ">
                          <div class="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                            <div class="theme-search-area-section-inner headerpickup">
                                <!--Using items input-->
                                <ng-select [items]="data" 
                                bindLabel="name" 
                                bindValue="name" 
                                name="pickup"
                                [(ngModel)]="pickup">
                                </ng-select>

                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 ">
                          <div class="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                            <div class="theme-search-area-section-inner headerdropoff">
                              
                                <ng-select [items]="data" 
                                bindLabel="name" 
                                bindValue="name" 
                                name="dropoff"
                                [(ngModel)]="dropoff">
                                </ng-select>
                            </div>
                          </div>
                        </div>

                    <div class="col-md-3 ">
                      <div class="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                        <div class="theme-search-area-section-inner">
                            <i class="theme-search-area-section-icon lin lin-calendar"></i>
                            <input required autocomplete="off" type="text"
                            placeholder="Datepicker"
                            class="theme-search-area-section-input"
                            name="traveldate"
                            [(ngModel)]="traveldate"
                            [minDate]="today"
                            #dp="bsDatepicker"
                            bsDatepicker
                            [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                            [bsValue]="bsValue">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <button class="theme-search-area-submit _mt-0 _tt-uc theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-sm theme-search-area-submit-primary">Make a Booking</button>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="theme-search-area-inline _desk-h theme-search-area-inline-white">
            <h4 class="theme-search-area-inline-title">{{ this.bookingdetails.pickupname}} - {{this.bookingdetails.dropoffname}}</h4>
            <p class="theme-search-area-inline-details">{{this.bookingdetails.traveldate}}</p>
           
          </div>
        </div>
</div>
    <div class="theme-page-section margin-grey">
      <div class="container">
        <div class="bus-listing-page  header-displayed">
            <div class="page-content row">
                <div class="col-md-12">
                        <h2 *ngIf="isReturnTicket" class="theme-search-area-title text-center">Return Ticket</h2>
                </div>
                <div class=" col-md-12" id="search-results-wrapper">
                    <div class="col-md-12">
                            <h2 *ngIf="isReturnTicket" class="theme-search-area-title text-center">Return Ticket</h2>
                    </div>
                <h2 class="city-labels  d-none d-sm-none d-md-block">
                  {{fromPlaceholder}}
                  →
                  {{toPlaceholder}}
                </h2>
                <div class="row">
                        <div class="col-lg-12 col-no-gap  d-none d-sm-none d-md-inline">
                                <ul class="dates ">
                                        <li class="prev-container">
                                            <a *ngIf="!checkDates()" class="prev"  (click)="changeDate(beforeyesterday)" >
                                            ‹
                                    </a></li>
                                    <li  class="yesterday-container">
                                        <a *ngIf="!checkDates()" class="yesterday"  (click)="changeDate(yesterday)">
                                            <strong>
                                                    {{yesterday  | date:'EEEE, d MMM'}}
                                            </strong></a>
                                    </li>
                                    <li class="active-container"><a class="active" [routerLink]="[]"  (click)="changeDate(travelDateInit)" >
                                            <strong>
                                                    {{travelDateInit  | date:'EEEE, d MMM'}}
                                            </strong></a></li>
                                    <li class="tomorrow-container"><a class="tomorrow" [routerLink]="[]" (click)="changeDate(tomorrow)">
                                            <strong>
                                                    {{tomorrow  | date:'EEEE, d MMM'}}
                                            </strong></a></li>
                                    <li class="next-container"><a class="next" [routerLink]="[]" (click)="changeDate(aftertomorrow)">
                                            ›
                                        </a></li>
                                </ul>
                        </div>
                        <div class="col-lg-12 col-no-gap  d-lg-none">
                          <ul class="dates ">
                                  <li class="prev-container">
                                      <a *ngIf="!checkDates()" class="prev"  (click)="changeDate(beforeyesterday)" >
                                      ‹
                              </a></li>
                              <li  class="yesterday-container">
                                  <a *ngIf="!checkDates()" class="yesterday"  (click)="changeDate(yesterday)">
                                      <strong>
                                              {{yesterday  | date:'EE, d MMM'}}
                                      </strong></a>
                              </li>
                              <li class="active-container"><a class="active" [routerLink]="[]"  (click)="changeDate(travelDateInit)" >
                                      <strong>
                                              {{travelDateInit  | date:'EE, d MMM'}}
                                      </strong></a></li>
                              <li class="tomorrow-container"><a class="tomorrow" [routerLink]="[]" (click)="changeDate(tomorrow)">
                                      <strong>
                                              {{tomorrow  | date:'EE, d MMM'}}
                                      </strong></a></li>
                              <li class="next-container"><a class="next" [routerLink]="[]" (click)="changeDate(aftertomorrow)">
                                      ›
                                  </a></li>
                          </ul>
                  </div>
                </div>
                <div class="theme-search-results search-result-multiselect">

                    <div class="theme-search-results-item search-result" id="search-result-direct">
                        <div class="row">

                            <div class="b col-lg-12 rides-list currency-eur">
                              <div class="sr-head row  d-none d-sm-none d-md-flex">
                                  <div class="col-lg-4 col-lg-offset-2 time-transfer">
                                      <div class="row">
                                          <div class="col-lg-12 ride-timing">Departs/Arrives
                                              </div>
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="row">
                                          <div class="col-lg-8 col-lg-8 visible-sm visible-lg">Depature Time
                                              </div>
                                          <div class="col-lg-4 col-sm-4 col-md-4 col-lg-4">
                                              <div class="row">
                                                  <div class="col-lg-12 col-sm-4 col-md-12 col-lg-5 total">
                                                      Total
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                                <div id="results-group-container-direct" class="result-date-container">
                                  <app-nobuses *ngIf="results.length == 0 && !isResultsLoading "></app-nobuses>
                                        <app-results-loader *ngIf="isResultsLoading"></app-results-loader>
                                    <div  *ngFor="let result of results;let i = index" class=" ride-item-pair  row" >
                                        
                                        <div class="col-lg-12">
                                            <div class="sr-row row  ride--booked js-ride--booked _pd-10">
                                              <div  class="col-lg-4 time-transfer">
                                                  <img  class="mobile-images _mb-20" width="100" alt="Soores Bus" src="assets/img/images/easycoach.png">
                                                  <br >
                                                  <!-- <p *ngIf="!result.fleet.registration_number.includes(fromPlaceholder+'-')" class="text-danger  badge _mb-10">Highway bus</p> -->
                                                
                                              </div>
                                                <div class="col-lg-8 ">
                                                    <div class="row">
                                                        <div class="text-center col-sm-4 col-lg-4 visible-sm visible-lg">
                                                            <div class="row custom-separator d-none d-sm-none d-md-flex">
                                                                <div class="col-lg-4">
                                                                  <p class="item-time"> {{ pickUpInit}}</p>
                                                                </div>
                                                                <div class="col-lg-4">
                                                                  <div class="text-center">
                                                                    <div class="time-duration">
                                                                      <span></span>
                                                                      <div class="bg-white px-1"><i class="far fa-clock"></i></div>
                                                                      <span></span>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4">
                                                                  <p class="_pr-10 item-time">{{ dropOffInit}}</p>
                                                                </div>
                                                                <div class="col-lg-11">
                                                                  <p class="text-center item-description">{{ result.departure_time | convertTime}} </p>
                                                                  <p class="seats-notice "> {{result.number_of_available_seats}} Available seats</p>
                                                                </div>
                                                            
                                                            </div>
                                                            <div class=" d-lg-none row custom-separator">
                                                              <div class="col-lg-4 ">
                                                                  <div class="text-center d-flex align-items-center justify-content-between dpe_ariv_wrap">
                                                                    <li style="flex-basis:auto" class="list-inline-item text-start ps-0 pe-2">
                                                                    <div class="item-content text-start">
                                                                      <p class="item-time">{{ pickUpInit}}</p>
                                                                    </div>
                                                                    </li>
                                                                    <div class="text-center">
                                                                      <div class="time-duration">
                                                                        <span></span>
                                                                        <div class="bg-white px-1"><i class="far fa-clock"></i></div>
                                                                        <span></span>
                                                                      </div>
                                                                    </div>
                                                                    <li style="flex-basis:auto" class="list-inline-item text-end ps-2 pe-0">
                                                                      <div class="item-content text-end">
                                                                        <p class="item-time">{{ dropOffInit}}</p>
                                                                      </div>
                                                                    </li>
                                                                   
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                  <p class="text-center item-description">{{ result.departure_time | convertTime}} </p>
                                                                  <p class="text-center seats-notice ">{{result.number_of_available_seats}} Available seats</p>
                                                                </div>

                                                            </div> 
                                                        </div>
                                                        <div class="col-sm-8 col-lg-8 price-actions">
                                                            <div class="row">
                                                                <div
                                                                    class="col-lg-12 col-sm-4 col-md-12 col-lg-5 ">

                                                                    <div class="in-cart total"><span
                                                                            class="num pull-right _pb-10">
                                                                            <span *ngFor="let seat of result.seats_types;let m = index; ">
                                                                                <span class="theme-footer-section-title" *ngIf="m==0">
                                                                                 Ksh {{seat.fare}}
                                                                                </span>
                                                                              </span>
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                               
                                                                <div class="col-lg-12 col-sm-8 col-md-12 col-lg-7 action">
                                                                   <button type="submit" class="js-ride__action__form__btn reserve"  data-toggle="modal" data-target="#exampleModal" style="box-sizing: border-box;" (click)="selectSeats(result)">Select Seat</button>
                                                                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                <div *ngIf="dataLoading" class="seat-layout-loader"><div class="c-loader"><div class="c-loader-inner"><div class="loader"></div></div></div><span class="close-button ixi-icon-cross"></span></div>
                                                  <div >
                                                        <div class="modal left fade" id="exampleModal" tabindex="" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog" role="document">
                                                                <div class="modal-content">
                                                                    <div class="modal-header">
                                                                         
                                                                            <div class="seat-book-cntr-mobile">
                                                                                <div class="center-wrapper">
                                                                                    <div  class="seat-layout">
                                                                                        <div class="seat-layout-cntr">
                                                                                            <div class="seat-legends">
                                                                                                <div class="seat-legend u-ib">
                                                                                                    <div class="single-seat-legend u-ib ">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                    </div>
                                                                                                    <div class="u-ib text">Available</div>
                                                                                                </div>
                                                                                                <div class="seat-legend u-ib">
                                                                                                    <div class="single-seat-legend u-ib unavailable">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                    </div>
                                                                                                    <div class="u-ib text">Occupied</div>
                                                                                                </div>
                                                                                                <div class="seat-legend u-ib">
                                                                                                    <div class="single-seat-legend u-ib selected">
                                                                                                        <div class="top"></div>
                                                                                                        <div class="left"></div>
                                                                                                        <div class="right"></div>
                                                                                                        <div class="bottom"></div>
                                                                                                        <div class="back"></div>
                                                                                                    </div>
                                                                                                    <div class="u-ib text">Selected</div>
                                                                                                </div>
                    
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true" class="la la-close"></span>
                                                                              </button>
                                                                    </div>
                                                                     
                                                                      
                                                                     
                                                                    <div class="modal-body">
                                                                        <div class=" flex-sm-column flex-row">
                                                                            <div class="seat-book-cntr-mobile  " >
                                                                              <div  class="center-wrapper ">
                                                                                <div class="seat-layout">
                                                                                  <div class="seat-layout-cntr" >
                                                                                         <div class="col-lg-4">
                                                                                            <app-spinner  *ngIf="dataLoading && !seatErrors"></app-spinner>
                                                                                         </div>
                                                                                          <div  *ngIf="seatErrors" class="seat-book-error">
                                                                                            <p> Unable to load seats for this bus. Kindly try a different bus. Thank you.
                                                                                          </div>
                                                                                         
                                                                                          <div *ngIf="!dataLoading && !seatErrors" class="seat-layout">
                                                                                              <div  class="seat-rows u-ib">
                                                                                                      <div  class="bus-steering u-ib pull-right">
                                                                                                          <img class="" src="assets/img/greenline/bus_steering.png">
                                                                                                      </div>
                                                                                                  <div *ngFor="let rows of seatMobile" class="" >
                                                                                                      <div class="seat-row">
                                                                                                          <div  *ngFor="let sdata of rows"    class="seat u-ib ">
                                                                                                            <div  *ngIf="selectedBus.operator.alias !='mashpoa' && sdata.space_type =='business' || sdata.space_type =='bussiness'  || sdata.space_type =='conductor'" class="single-seat " (click)="seatSelect($event,selectedBus, sdata.id,sdata.space_type)" [ngClass]="(sdata.status =='available')? '':'unavailable'">
                                                                                                              <div class="left"></div>
                                                                                                              <div class="top"></div>
                                                                                                              <div class="right"></div>
                                                                                                              <div class="bottom"></div>
                                                                                                              <div class="back"></div>
                                                                                                              <div class="seat-no">{{sdata.id}}</div>
                                                                                                          </div>
                                                                                                              
                                                                                                              <div  *ngIf="sdata.space_type =='vip' || sdata.space_type =='first_class'" class="single-seat-vip" (click)="seatSelect($event,selectedBus, sdata.id,sdata.space_type)" [ngClass]="(sdata.status =='available')? '':'unavailable'">
                                                                                                                  <div class="left"></div>
                                                                                                                  <div class="top"></div>
                                                                                                                  <div class="right"></div>
                                                                                                                  <div class="bottom"></div>
                                                                                                                  <div class="back"></div>
                                                                                                                  <div class="seat-no">{{sdata.id}}</div>
                                                                                                              </div>

                                                                                                              <div  *ngIf="sdata.space_type =='normal' || sdata.space_type =='normal'" class="single-seat" (click)="seatSelect($event,selectedBus, sdata.id,sdata.space_type)" [ngClass]="(sdata.status =='available')? '':'unavailable'">
                                                                                                                <div class="left"></div>
                                                                                                                <div class="top"></div>
                                                                                                                <div class="right"></div>
                                                                                                                <div class="bottom"></div>
                                                                                                                <div class="back"></div>
                                                                                                                <div class="seat-no">{{sdata.id}}</div>
                                                                                                            </div>
                          
                          
                                                                                                              <div *ngIf=" sdata.space_type =='aisle' " class="no-single-seat"></div>
                                                                                                              <div *ngIf=" sdata.space_type =='skip'" class="no-single-seat"></div>
                                                                                                              <div *ngIf=" sdata.space_type =='blank' " class="no-single-seat"></div>
                                                                                                              <div *ngIf=" sdata.space_type =='driver'  " class="no-single-seat"></div>
                                                                                                              <div *ngIf=" sdata.space_type =='door'  " class="no-single-seat"></div>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                          </div>
                                                                                  </div>
                                                                                 </div>
                                                                            </div>
                                                                             </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="modal-footer sidebar-footer " *ngIf="totalFare > 0">
                                                                        <p><b><span  class="text-green price-labels-lg"> Ksh {{totalFare}}</span></b>
                                                                        </p>
                                                                        <button (click)="submitForm(selectedBus)" type="submit" class="btn btn-secondary reserve" data-dismiss="modal" aria-label="Close" [ngClass]="( totalFare > 0) ? 'enabled':'disabled'" >BOOK NOW</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                

                                                        <div class="u-clear"></div>

                                                  </div>
                                                </div>
                                                
                                            </div>

                                        </div>
                                    </div>


                                </div>
                                <div class="row">
                                    <div id="multiselect-error" class="col-lg-12 rides-list" data-group="direct"
                                        style="display: none">
                                        <div class="search-result">
                                            <div class="search-result__not-found">
                                                <div class="search-result-suggestions--cities"></div>
                                                <div class="search-result__not-found__text">Unfortunately there
                                                    are no trips on this route on
                                                    the selected date. Please adjust the date of the trip to get
                                                    results.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
                <div class="offer-section u-ib u-layout-col-2">

                </div>
            </div>
        </div>
      </div>
    </div>
      <div class="theme-page-section theme-page-section-white">
        <div class="container">
          <div class="row row-col-static" id="sticky-parent">
            <div class="col-md-11">
              <div class="theme-search-results _mb-10">
              </div>
            </div>
          </div>
        </div>
      </div>
<app-footer></app-footer>
