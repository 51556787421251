import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID, isDevMode } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { QRCodeModule } from 'angularx-qrcode';
import { NgSelectModule } from '@ng-select/ng-select';
// RECOMMENDED
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import enke from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
registerLocaleData(enke);

import { CarouselModule } from 'ngx-owl-carousel-o';


import { AppComponent } from './app.component';
import { HeadermainComponent } from './shared/headermain/headermain.component';
import { HeaderhomeComponent } from './shared/headerhome/headerhome.component';
import { FooterComponent } from './shared/footer/footer.component';
import { PaymentsComponent } from './payments/payments.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { BookingComponent } from './booking/booking.component';
import { ResultsLoaderComponent } from './shared/results-loader/results-loader.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { NobusesComponent } from './nobuses/nobuses.component';
import { SuccessComponent } from './success/success.component';
import { CounterDirective } from './directives/counter.directive';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { CarhireComponent } from './carhire/carhire.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { MyBookingComponent } from './my-booking/my-booking.component';
import { ConvertTimePipe } from './pipes/convert-time.pipe';
import { ParcelComponent } from './parcel/parcel.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';



@NgModule({
  declarations: [
    AppComponent,
    HeadermainComponent,
    HeaderhomeComponent,
    FooterComponent,
    PaymentsComponent,
    HomeComponent,
    NotFoundComponent,
    SpinnerComponent,
    BookingComponent,
    ResultsLoaderComponent,
    ConfirmComponent,
    NobusesComponent,
    SuccessComponent,
    CounterDirective,
    AboutusComponent,
    ContactusComponent,
    CarhireComponent,
    UserProfileComponent,
    MyBookingComponent,
    ConvertTimePipe,
    ParcelComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    CustomerFeedbackComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    QRCodeModule,
    CarouselModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
