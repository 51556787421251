import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../services/index'
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  sub:any;
  ticketDetails:any;
  rawTicketDetails:any;
  bookingdetails:any;
  condfirmData: any
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService
  ) { }

  ngOnInit() {
    // this.sub = this.route.params.subscribe(params => {
    //   let data= params['id'];
    //   let decodec:any=JSON.parse(atob(data));
    //   this.ticketDetails=decodec;
    //   //let decoded=JSON.parse(atob(data));
    //   console.log(decodec);

    // });


    this.dataService.successValueChange.subscribe(message =>{

      if(!message){
        message = localStorage.getItem('success-buupass')
      }else{
        localStorage.setItem('success-buupass', message)
      }

      let booking=JSON.parse(Base64.decode(message));
      this.ticketDetails=booking.successData.data.data;
      this.rawTicketDetails = booking.successData;
      this.bookingdetails = booking.bookingdetails;
      this.condfirmData = booking.condfirmData.confirm_data;

      this.ticketDetails=booking.successData.data.data;
      this.rawTicketDetails = booking.bookingdetails;

      if(!message){
        message = localStorage.getItem('success-buupass')
        if(!message) {
         this.router.navigate(['/'])
       }
      }else{
        localStorage.setItem('success-buupass', message)
      }

       if(!message) {
         this.router.navigate(['/'])
       }
    });




  }

}
