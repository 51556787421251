import { Component, OnInit } from '@angular/core';
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router} from "@angular/router"
import { Base64 } from 'js-base64';
import { DataService } from '../../services/index'
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  today=new Date();
  returning=false;
  constructor(
    private router:Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
  }




clickTopCity(depature,destination){

  console.log(depature,destination)
  var traveldate = formatDate(this.today,"yyyy-MM-dd","en-KE");
  let kisumu={
      'pickup' : depature,
      'dropoff' : destination,
      'pickupname' :depature,
      'dropoffname' : destination,
      'traveldate' : traveldate,
      'returndate': '',
      'returning' : this.returning
    }

    console.log(kisumu)

    let p=Base64.encode(JSON.stringify(kisumu));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])

}




  

}
